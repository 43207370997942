import React from "react";
import { useTrans } from "system/translations/hooks";
import Loader from "components/atoms/Loader";
import VolumeItem from "components/atoms/AnalyticVolumItem";

const AnalyticsTotalVolume: React.FC<any> = ({
  data,
  isLoading,
  isDefaultTransactionFilter,
  transactionType
}) => {
  const { _t } = useTrans();

  if (isLoading) {
    return <Loader formOverlay />;
  }
  const {
    balance,
    currency,
    balanceChangeRelativeToPreviousPeriod,
    totalVolume,
    totalChangeRelativeToPreviousPeriod,
    depositVolume,
    depositChangeRelativeToPreviousPeriod,
    withdrawalVolume,
    withdrawalChangeRelativeToPreviousPeriod,
  } = data;

  return (
    <div className="total-volume__container total-volume">
      {(isDefaultTransactionFilter ||
        (transactionType.includes("WITHDRAWAL") && transactionType.includes("DEPOSIT"))) && (
          <>
            <VolumeItem
              title={_t("balance")}
              subTitle={_t("deposits_{connector}_withdrawals", { connector: "-" })}
              amount={balance}
              currency={currency}
              change={balanceChangeRelativeToPreviousPeriod}
            />
            <VolumeItem
              title={_t("total_volume")}
              subTitle={_t("deposits_{connector}_withdrawals", { connector: "&" })}
              amount={totalVolume}
              currency={currency}
              change={totalChangeRelativeToPreviousPeriod}
            />
          </>
        )}
      {(isDefaultTransactionFilter || transactionType.includes("DEPOSIT")) && (
        <VolumeItem
          title={_t("deposit_volume")}
          amount={depositVolume}
          currency={currency}
          change={depositChangeRelativeToPreviousPeriod}
        />
      )}
      {(isDefaultTransactionFilter || transactionType.includes("WITHDRAWAL")) && (
        <VolumeItem
          title={_t("withdrawals_volume")}
          amount={withdrawalVolume}
          currency={currency}
          change={withdrawalChangeRelativeToPreviousPeriod}
        />
      )}
    </div>
  );
};

export default AnalyticsTotalVolume;
