import React from "react";
import { CaretDown16, CaretUp16 } from "components/atoms/IconsCreateFactory"
import classNames from "classnames";

const VolumeItem: React.FC<{
  title: string;
  subTitle?: string;
  amount: number;
  currency?: string;
  change: number;
}> = ({ title, subTitle, amount, currency, change }) => (
    <div className="total-volume__item">
      <div>
        <div className="total-volume__item-title">{title}</div>
        {subTitle && <div className="total-volume__item-sub-title">{subTitle}</div>}
      </div>
      <div className="total-volume__item-amount">
        {amount.toLocaleString()} {currency || ''}
      </div>
      <div
        className={classNames("total-volume__item-percantage", {
          "total-volume__item-percantage--positive": change > 0,
          "total-volume__item-percantage--negative": change < 0,
        })}
      >
        {change > 0 ? <CaretUp16 /> : <CaretDown16 />}
        {change ? `${change.toLocaleString()} %` : "0 %"}
      </div>
    </div>
  );

export default VolumeItem
