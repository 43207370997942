import { Column as ColumnType } from "react-table";
import { CopyButton, Link } from "carbon-components-react";
import InfoTag from "components/atoms/InfoTag";
import React, { useMemo } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import { useModuleData } from "./hooks";
import config from "../../system/config";
import { ArrayOptionsMapRes } from "system/helpers/types";
import { exportFields, filterOptions } from "./constants";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { CreateEditFormConfigParams } from "modules/translations/types";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import CopyButtonWrapper from "components/atoms/CopyButtonWrapper";
import { TableColumnType } from "modules/table/types";

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      /*      {
        Header: _t("merchant"),
        id: "merchant1",
        accessor: "merchant.name",
        Cell: ({ isExpanded, onExpand, row, showDetails }: any) => {
          return (
            <span className={"cell-wrapper"}>
              <span onClick={onExpand}>
                {isExpanded ? <ChevronUp16 /> : <ChevronDown16 />}
              </span>
              <Link onClick={() => showDetails(row.original, "merchant")}>
                {row?.original?.merchant?.name}
              </Link>
            </span>
          );
        }
      },*/
      {
        Header: _t("merchant"),
        id: "merchant",
        accessor: "merchant.name",
        width: 140,
        disableSortBy: true,
        mobileVisible: true,
        isMobileTitle: true,
        // Cell: (props: any) => {
        //   return (
        //     <>
        //       <RoleWrapper
        //         defaultComponent={
        //           <span>{props?.row?.original?.merchant.name}</span>
        //         }
        //         accessTo={"ROLE_VIEW_MERCHANT_DETAILS"}
        //       >
        //         <div style={{ display: "flex", width: "100%" }}>
        //           <Link
        //             onClick={() =>
        //               props.showDetails(props.row.original, "merchants")
        //             }
        //             style={{ width: "100%" }}
        //           >
        //             {props?.row?.original?.merchant.name}
        //           </Link>
        //         </div>
        //       </RoleWrapper>
        //     </>
        //   );
        // },
      },
      {
        Header: _t("uuid"),
        id: "UUID",
        accessor: "transactionUUID",
        minWidth: 225,
        disableSortBy: true,
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <>
              <RoleWrapper
                defaultComponent={
                  <span>{props?.row?.original?.transactionUUID}</span>
                }
                accessTo={"ROLE_VIEW_TRANSACTION_DETAILS"}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <Link
                    onClick={() =>
                      props.showDetails(props.row.original, "transaction")
                    }
                    style={{ width: "100%" }}
                  >
                    {props?.row?.original?.transactionUUID}
                  </Link>
                  <CopyButtonWrapper
                    copyValue={props?.row?.original?.transactionUUID}
                  />
                </div>
              </RoleWrapper>
            </>
          );
        },
      },
      {
        Header: _t("amount"),
        accessor: "amount",
        id: "amount1",
        width: 120,
        disableSortBy: true,
        mobileVisible: true,
      },
      {
        Header: _t("processed_amount"),
        accessor: "convertedAmount",
        id: "convertedAmount1",
        width: 145,
        disableSortBy: true,
      },
      {
        Header: _t("status"),
        accessor: "status",
        id: "status1",
        width: 180,
        disableSortBy: true,
        mobileVisible: true,
        Cell: (props: any) => (
          <>
            <InfoTag
              status={props?.row?.original?.status}
              label={props?.row?.original?.status}
            />
            {props?.row?.original?.ipn && (
              <InfoTag fitContent reverse label={"IPN"} status={"info"} />
            )}
          </>
        ),
      },
      {
        Header: _t("message"),
        accessor: "message",
        id: "message",
        disableSortBy: true,
        mobileVisible: true,
        isMobileHidden: true,
      },
      {
        Header: _t("type"),
        accessor: "type",
        id: "type1",
        width: 120,
        disableSortBy: true,
        mobileVisible: true,
      },
      {
        Header: _t("country"),
        accessor: "billingCountry",
        id: "billingCountry1",
        width: 90,
        disableSortBy: true,
        mobileVisible: true,
        isMobileHidden: true,
      },
      {
        Header: _t("payment_method"),
        accessor: "paymentMethod",
        id: "paymentMethod1",
        disableSortBy: true,
        mobileVisible: true,
        isMobileHidden: true,
      },
      {
        Header: _t("gateway_name"),
        accessor: "gatewayName",
        id: "gatewayName1",
        width: 90,
        disableSortBy: true,
        mobileVisible: true,
      },
      {
        Header: _t("client"),
        accessor: "client",
        id: "client1",
        disableSortBy: true,
        mobileVisible: true,
        isMobileHidden: true,
        Cell: (props: any) => {
          return (
            <RoleWrapper
              defaultComponent={
                <span>{props.row?.original?.client?.name}</span>
              }
              accessTo={"ROLE_VIEW_TRANSACTION_CLIENT"}
            >
              <Link
                onClick={() =>
                  props.showDetails(props.row.original, "customer")
                }
              >
                {props.row?.original?.client?.name}
              </Link>
            </RoleWrapper>
          );
        },
      },
      {
        Header: _t("merchant_client_id"),
        accessor: "client.merchantClientId",
        id: "merchant_client_id1",
        minWidth: 225,
        disableSortBy: true,
        mobileVisible: true,
        isMobileHidden: true,
        Cell: (props: any) => {
          return (
            <div style={{ display: "flex", width: "100%" }}>
              {props?.row?.original?.client.merchantClientId}
              {props?.row?.original?.client.merchantClientId &&
              <CopyButtonWrapper
                copyValue={props?.row?.original?.client.merchantClientId}
                toastMessage={_t("{item}_copied", {
                  item: _t("merchant_client_id")
                })}
                customStyles={{
                  marginLeft: "-20px"
                }}
              />
            }
              </div>
          )
        }
      },
      {
        Header: `${_t("user")} ${_t("id")}`,
        accessor: "client.id",
        id: "client.id",
        width: 145,
        disableSortBy: true,
        mobileVisible: true,
        isMobileHidden: true,
        Cell: (props: any) => {
          return (
            <div style={{ display: "flex", width: "100%" }}>
              {props?.row?.original?.client?.id}
              {props?.row?.original?.client?.id &&
              <CopyButtonWrapper
                copyValue={props?.row?.original?.client?.id}
                toastMessage={_t("{item}_copied", {
                  item: `${_t("user")} ${_t("id")}`
                })}
                customStyles={{
                  marginLeft: "-20px"
                }}
              />
            }
              </div>
          )
        }
      },
      {
        Header: _t("merchant_order_no"),
        accessor: "referenceNumber",
        id: "referenceNumber",
        width: 145,
        disableSortBy: true,
        mobileVisible: true,
        isMobileHidden: true,
        Cell: (props: any) => {
          return (
            <div style={{ display: "flex", width: "100%" }}>
              {props?.row?.original?.referenceNumber}
              {props?.row?.original?.referenceNumber &&
              <CopyButtonWrapper
                copyValue={props?.row?.original?.referenceNumber}
                toastMessage={_t("{item}_copied", {
                  item: _t("merchant_order_no")
                })}
                customStyles={{
                  marginLeft: "-20px"
                }}
              />
            }
              </div>
          )
        }
      },
      {
        Header: _t("psp_order_no"),
        accessor: "gatewayTransactionId",
        id: "gatewayTransactionId",
        width: 120,
        disableSortBy: true,
        mobileVisible: true,
        isMobileHidden: true,
        Cell: (props: any) => {
          return (
            <div style={{ display: "flex", width: "100%" }}>
              {props?.row?.original?.gatewayTransactionId}
              {props?.row?.original?.gatewayTransactionId &&
              <CopyButtonWrapper
                copyValue={props?.row?.original?.gatewayTransactionId}
                toastMessage={_t("{item}_copied", {
                  item: _t("psp_order_no")
                })}
                customStyles={{
                  marginLeft: "-20px"
                }}
              />
            }
              </div>
          )
        }
      },
      {
        Header: _t("created_date"),
        accessor: "createdDate",
        id: "createdDate",
        width: 160,
        disableSortBy: true,
        mobileVisible: true,
      },
      {
        Header: _t("updated_date"),
        accessor: "updatedDate",
        id: "updatedDate",
        width: 160,
        disableSortBy: true,
        mobileVisible: true,
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const {
    merchantsOptions,
    paymentMethodsOptions,
    paymentGatewaysOptions,
    statusReasonsOptions,
    countriesOptions
  } = useModuleData();
  // const operationTypes = config.operationTypes;
  const transactionTypes = config.transactionTypes;
  const transactionStatuses = config.transactionStatuses;
  const ipnSentOptions = config.ipnSentOptions;
  const exportFieldsOptions = exportFields(_t);
  const filterByOptions: ArrayOptionsMapRes = useMemo(
    () => filterOptions(_t),
    [_t]
  );
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.merchantId({
        componentProps: {
          items: merchantsOptions,
        },
      }),
      fields.filters.paymentMethod({
        componentProps: {
          items: paymentMethodsOptions,
          withAsteriskOnSelectAll: true
        },
      }),
      fields.filters.paymentGateway({
        componentProps: {
          items: paymentGatewaysOptions,
          withAsteriskOnSelectAll: true
        },
      }),
      // fields.filters.operation({
      //   componentProps: {
      //     items: operationTypes,
      //   },
      // }),
      fields.filters.status({
        componentProps: {
          items: transactionStatuses,
        },
      }),
      fields.filters.type({
        componentProps: {
          items: transactionTypes,
        },
      }),
      fields.filters.fields({
        componentProps: {
          items: exportFieldsOptions,
        },
      }),
      fields.filters.ipnSent({
        componentProps: {
          items: ipnSentOptions,
          withAsteriskOnSelectAll: true
        },
      }),
      fields.filters.statusReason({
        componentProps: {
          items: statusReasonsOptions,
          withAsteriskOnSelectAll: true
        },
      }),
      fields.filters.country({
        componentProps: {
          items: countriesOptions,
          withAsteriskOnSelectAll: true
        },
      }),
      fields.filters.filterValue(),
      fields.filters.filterField({
        componentProps: {
          items: filterByOptions,
        },
      }),
      fields.filters.date(),
      fields.general.submitBtn(),
    ];
  }, [merchantsOptions, paymentMethodsOptions, paymentGatewaysOptions]);
  return formConfig;
};

export const useUpdateStatusConfig = (params: any): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.transactionUUID(),
      fields.formFields.transactionStatus(),
      fields.formFields.comment(),
      fields.general.submitBtn({
        componentProps: { label: _t("submit"), isLoading: params.isLoading },
      }),
    ];
  }, [params.isLoading]);
  return formConfig;
};

/*      {
        component: MultiSelect,
        name: "merchantId",
        valuePreview: true,
        componentProps: {
          items: merchantsOptions,
          label: _t("merchants"),
          light: true,
          wrapClassPrefix: "menu-full-width",
        },
        columnParams: {
          className: "maxWidthContent",
        },
      },
      {
        component: MultiSelect,
        name: "paymentMethod",
        valuePreview: true,
        componentProps: {
          items: paymentMethodsOptions,
          label: _t("payment_methods"),
          light: true,
          wrapClassPrefix: "menu-full-width",
        },
        columnParams: {
          className: "maxWidthContent",
        },
      },
      {
        component: MultiSelect,
        name: "paymentGateway",
        valuePreview: true,
        componentProps: {
          items: paymentGatewaysOptions,
          label: _t("payment_gateways"),
          light: true,
          wrapClassPrefix: "menu-full-width",
        },
        columnParams: {
          className: "maxWidthContent",
        },
      },
      {
        component: MultiSelect,
        name: "operation",
        valuePreview: true,
        componentProps: {
          items: operationTypes,
          label: _t("operation_types"),
          light: true,
          wrapClassPrefix: "menu-full-width",
        },
        columnParams: {
          className: "maxWidthContent",
        },
      },
      {
        component: MultiSelect,
        name: "status",
        valuePreview: true,
        componentProps: {
          items: transactionStatuses,
          label: _t("transaction_statuses"),
          light: true,
          wrapClassPrefix: "menu-full-width",
        },
        columnParams: {
          className: "maxWidthContent",
        },
      },
      {
        component: MultiSelect,
        name: "type",
        valuePreview: true,
        componentProps: {
          items: transactionTypes,
          label: _t("transaction_types"),
          light: true,
          wrapClassPrefix: "menu-full-width",
        },
        columnParams: {
          className: "maxWidthContent",
        },
      },
      {
        component: MultiSelect,
        name: "fields",
        valuePreview: true,
        componentProps: {
          items: exportFieldsOptions,
          label: _t("export_fields"),
          light: true,
          wrapClassPrefix: "menu-full-width",
        },
        columnParams: {
          className: "maxWidthContent",
        },
      },
      {
        component: Search,
        name: "filterValue",
        componentProps: {
          labelText: _t("search"),
          placeHolderText: _t("search"),
        },
        columnParams: {
          md: { span: 5 },
          lg: { span: 5 },
        },
        emptySpace: {
          md: 16,
          lg: 16,
        },
      },
      {
        component: Select,
        name: "filterField",
        componentProps: {
          hideLabel: true,
          items: filterByOptions,
        },
        columnParams: {
          md: 3,
          lg: 3,
        },
      },
      {
        component: DateRange,
        name: "date",
        valuePreview: true,
        componentProps: {
          labelText: _t("date_range"),
          fromName: "fromDate",
          toName: "toDate",
        },
      },
      {
        component: Btn,
        name: "",
        componentProps: {
          kind: "secondary",
          type: "submit",
          label: _t("apply_filters"),
        },
        columnParams: {
          className: "maxWidthContent",
        },
      },*/
