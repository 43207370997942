import React from "react";
import Card from "components/atoms/Card";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import { generatePath, useHistory } from "react-router-dom";
import ButtonLink from "components/atoms/ButtonLink";
import { Add16, Edit16 } from "components/atoms/IconsCreateFactory";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useSearchFormConfig, useTableColumns } from "./constantHooks";
import { useFEListSort, useFilters, useListSort } from "system/helpers/hooks";
import { useGetMerchantsFees, useUpdateAdvancedMerchantsFees } from "./apiHooks";
import { APP_ROUTES } from "system/router/constants";
import { useModal } from "modules/modal/hooks";
import HistoryDetails from "./components/HistoryDetails";
import { Button } from "carbon-components-react";
import { useReorderColumns } from "./hooks";
import AdvancedSettingsMerchantBalance from "components/molecules/AdvancedSettingsMerchantBalance";
import Loader from "components/atoms/Loader";
import { createEditMerchantFeesItemCellValidation } from "./validation"
import { isMobile } from "system/config/constant";

const MerchantsFees = () => {
  const history = useHistory();
  const { showModal } = useModal();
  const { _t } = useTrans();
  const columns = useTableColumns();
  const { filters, setFilters } = useFilters({});
  const { data = [], isLoading } = useGetMerchantsFees(filters);
  const {
    setColumnOrderPSPToMerchant,
    setColumnOrderGroupBy,
    groupBy,
  } = useReorderColumns(columns);
  const { sort, setSort } = useListSort({});
  const sortData = useFEListSort(data, sort);
  const searchFormConfig = useSearchFormConfig(setColumnOrderPSPToMerchant, groupBy);
  const { mutate: onUpdateAdvanced } = useUpdateAdvancedMerchantsFees();

  const onFilterSubmit = (data: any) => {
    const normalizeDate = { ...data };
    delete normalizeDate.pspToMerchant;
    setFilters(normalizeDate);
  };

  const onUpdate = ({ id, merchantId }: any) => {
    const link = generatePath(APP_ROUTES.merchantsBalanceV2.merchantsFees.update, {
      merchantId,
      id,
    });
    history.push(link, history.location);
  };

  const actionMenuItems = [
    { title: _t("edit"), onClick: onUpdate, icon: <Edit16 /> },
  ];

  const showHistory = ({ data, isLoading }: any) => {
    showModal({
      componentProps: {
        data,
        isLoading
      },
      modalHeading: _t("edit_history"),
      component: HistoryDetails,
      footer: Button,
      footerProps: {
        style: {
          display: 'none'
        }
      }
    });
  };

  const showAdvancedSettings = (formConfig: any, initialValues: any) => {
    showModal({
      size: 'sm',
      isShowScale: false,
      className: 'advancedModal',
      componentProps: {
        formConfig: formConfig,
        initialValues,
        onUpdate: onUpdateAdvanced,
        validation: createEditMerchantFeesItemCellValidation(_t, initialValues.fieldName )
      },
      modalHeading: _t('set_new_value'),
      component: AdvancedSettingsMerchantBalance,
      footer: Button,
      footerProps: {
        style: {
          display: 'none'
        }
      }
    }, 'advancedSettings');
  };

  
  return (
    <Card
      title={_t("merchants_fees")}
      subtitle={_t("merchants_fees_subtitle")}
      titleClassName="card__head__title--with__subtitle"
      extra={
        <ButtonLink
          isExpressive
          renderIcon={Add16}
          linkTo={APP_ROUTES.merchantsBalanceV2.merchantsFees.create}
          {...(isMobile 
            ? { className: "button-create__link" } 
            : { title: _t("create_new_merchant_fee")})}
        />
      }
    >
      <FormBuilder
        formItemsConfig={searchFormConfig}
        showSubmit={false}
        formProps={{
          submitBtnLabel: _t("apply_filters"),
          onSubmit: onFilterSubmit,
          initialValues: { pspToMerchant: groupBy },
        }}
      />
      <Table
        totalRecords={data?.length || 0}
        columns={columns}
        isLoading={isLoading}
        data={sortData || []}
        isPagination
        groupBy={{ setColumnOrderGroupBy }}
        onSort={setSort}
        cellProps={{
          onUpdate,
          actionMenuItems,
          showHistory,
          showAdvancedSettings
        }}
      />
    </Card>
  );
};

export default MerchantsFees;
