import React, { useEffect, useMemo, useState } from "react";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useFilters } from "system/helpers/hooks";
import { format } from "date-fns";
import config from "system/config";
import { useSearchFormConfig } from "./constantHooks";
import { useGetAnalytics } from "./apiHooks";
import { isEmpty } from "lodash";
import { useGetDataByFilter, usePrepareDataToAnalyticSection } from "./hooks";
import AnalyticDiagram from "./components/AnalyticDiagram";
import NoData from "components/atoms/NoData";
import RefreshTimer from "components/atoms/RefreshTimer";

const Analytics = () => {
  const { _t } = useTrans();
  const filterInitialValues = useMemo(
    () => ({
      fromDate: format(new Date(), config.dateFormat),
      toDate: format(new Date(), config.dateFormat),
      differentiation: 'day',
    }),
    []
  );
  const { filters, setFilters } = useFilters(filterInitialValues);
  const [convertedFilters, setConvertedFilters] = useState({});
  const [currenciesOptions, setCurrenciesOptions] = useState<any>([{ depositCurrencies: [] }, { withdrawalCurrencies: [] }])
  const requestParams = useMemo(() => ({ ...filters }), [filters]);
  const searchFormConfig = useSearchFormConfig();

  const onFilterSubmit = (data: any) => {
    const normalizeDate = {
      ...data,
      differentiation: filters.differentiation
    };
    if (data?.date) {
      normalizeDate.fromDate = data?.date?.fromDate;
      normalizeDate.toDate = data?.date?.toDate;
    }
    delete normalizeDate.date;
    delete normalizeDate.searchFrom;

    setFilters(normalizeDate);
  };
  const onPeriodChange = (val: any) => {
    setFilters({
      ...filters,
      differentiation: val
    })
  }
  const onConverted = (val: boolean) => {
    setConvertedFilters(val ? { convertTo: 'EUR' } : {})
  }
  const onReloadData = () => {
    setFilters({ ...filters, sendDate: new Date().getTime()});
  };
  const requestParamsConverted = useMemo(() => ({ ...filters, ...convertedFilters }), [convertedFilters, filters]);
  const {
    data: analyticsData = [],
    isLoading,
    isError,
    dataUpdatedAt
  } = useGetAnalytics(isEmpty(convertedFilters) ? requestParams : requestParamsConverted)
  const {data} = useGetDataByFilter(filters, convertedFilters)
  const paramsForPrepareData = useMemo(() => ({ analyticsData, data, convertedFilters, setCurrenciesOptions}), [data, analyticsData]);
  const { data: prepareData } = usePrepareDataToAnalyticSection(paramsForPrepareData)

  return (
    <Card title={
      <div>
        <div>{_t("analytics")}</div>
        <div className={"card__head__subtitle"}>
          {dataUpdatedAt ? (
            <RefreshTimer
              onClick={onReloadData}
              startDate={dataUpdatedAt}
            />) : null}
        </div>
      </div>
    }>
      <FormBuilder
        formItemsConfig={searchFormConfig}
        showSubmit={false}
        formProps={{
          submitBtnLabel: _t("apply_filters"),
          onSubmit: onFilterSubmit,
          initialValues: filterInitialValues,
        }}
      />
      {isError
        ?  <NoData text={_t("error_text")} />
        : <AnalyticDiagram 
            filters={filters}
            onPeriodChange={onPeriodChange}
            onConverted={onConverted}
            analyticsData={analyticsData}
            isLoading={isLoading}
            data={prepareData}
            currenciesOptions={currenciesOptions}
            convertedFilters={convertedFilters}
          />
      }
    </Card>
  );
};

export default Analytics;
