import React from "react";
import * as Yup from "yup";
import { ArrowRight20 } from "components/atoms/IconsCreateFactory"
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useLoginTfa } from "modules/auth/apiHooks";
import Loader from "components/atoms/Loader";
import { useTfaProcessFormConfig } from "modules/auth/constantHooks";

interface TfaProcessFormProps {
  payloadData: {
    [key: string]: string | number | boolean;
  };
}

const TfaProcessForm: React.FC<TfaProcessFormProps> = ({ payloadData }) => {
  const { mutateAsync, isLoading } = useLoginTfa();
  const { _t } = useTrans();
  const formConfig = useTfaProcessFormConfig();
  const onSubmit = async (data: any) => {
    await mutateAsync({ ...data, ...payloadData });
  };

  const TfaSchema = (length = 6) =>
    Yup.object().shape({
      authenticationCode: Yup.string()
        .matches(/^[0-9]+$/, _t("must_be_a_number"))
        .min(length, _t("verify_code_require_{length}_digit", { length }))
        .max(length, _t("verify_code_require_{length}_digit", { length }))
        .required(_t("required")),
    });

  return (
    <>
      <FormBuilder
        formItemsConfig={formConfig}
        formProps={{
          onSubmit: onSubmit,
          initialValues: { authenticationCode: "" },
          validationSchema: TfaSchema(),
          submitBtnKind: "primary",
          submitBtnLabel: _t("verify"),
          renderIcon: ArrowRight20,
        }}
      />
      {isLoading && <Loader formOverlay description={_t("loading")} />}
    </>
  );
};

export default TfaProcessForm;
